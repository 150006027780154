import * as Ajax from './ajax'

$(document).ready(function () {

   // スクロール禁止
   function no_scroll() {
    document.addEventListener("mousewheel", scroll_control, { passive: false });
    document.addEventListener("touchmove", scroll_control, { passive: false });
  }
  // スクロール禁止解除
  function return_scroll() {
    document.removeEventListener("mousewheel", scroll_control, { passive: false });
    document.removeEventListener('touchmove', scroll_control, { passive: false });
  }

  // スクロール関連メソッド
  function scroll_control(event) { event.preventDefault(); }

  function show_dialog(el) {
    $(el).fadeIn()
    $(".profile-dialog-bg").fadeIn()
    no_scroll()
  }

  function close_dialog() {
    $(".profile-dialog-bg").fadeOut()
    $(".profile-dialog").fadeOut()
    return_scroll()
  }

  //　ダイアログ背景タップ
  $(".profile-dialog-bg").click(function() {
    close_dialog()
  })

  $('a.reservation-or-message').click(function() {
    $('#dialog-reservation-password').attr('data-move-to-link', $(this).data('move-to-link'))
    $('#dialog-reservation-password').attr('data-stekina-id', $(this).data('stekina-id'))
    $('.password-dialog-title').text($(this).data('owner-name') + "さんの予約やメッセージについて")
    $('.password-dialog-image').html("<img src=" + $(this).data('owner-image') + " onerror='' style='width: 33px; height: 33px; border-radius: 100px; margin: 0;' >")

    tapped_need_password()
  })


  function tapped_need_password() {
    show_dialog($('#dialog-reservation-password'))
  }

  // 予約パスワードのチェック
  $('.check-reservation-password-button').click(function() {
    const stekinaId = $('#dialog-reservation-password').data('stekina-id')
    const password = $('.reservation-password').val()
    const $alertInvalid = $('.alert-invalid-reservation-password')

    Ajax.checkReservationPassword(stekinaId, password, (result) => {
      if(result) {
        close_dialog()
        var link = $('#dialog-reservation-password').data('move-to-link')
        location.href = link
      }
      else {
        // エラー表示
        $alertInvalid.show()
      }
    },
    () => {
      $alertInvalid.show()
    })
  })

  // ----------------------------------------
  // ダイアログ共通
  // ----------------------------------------

  // ダイアログ閉じるボタン
  $('body').on('click', '.close-button,.dialog-window-close', function (e) {
    $('.dialog').hide()
  })
  // ダイアログ背景で閉じる
  $('body').on('click', '.dialog', function (e) {
    $('.dialog').hide()
  })
  // ダイアログ背景以外では閉じない
  $('body').on('click', '.dialog-window', function (e) {
    e.stopPropagation()
  })
})

/**
 * アニメーションつきでステキ数のセットを行う
 */
const STEKI_METER_HEIGHTS = ['0%', '25%', '33%', '40%', '48%', '56%', '65%', '74%', '83%', '92%', '100%']

export function setStekiWithAnimate(loveButton, numSteki, numTotalSteki) {
  var loveButtonParent = loveButton.parent()
  loveButton.data('num-suteki', numSteki)
  loveButton.data('num-total-suteki', numTotalSteki)

  var animationLoveButton = loveButton.next('.love-button')
  animationLoveButton.addClass('sutekiscalenormal').delay(500).queue(function (next) {
    animationLoveButton.removeClass('sutekiscalenormal')
    next()
  })

  loveButtonParent.find('.suteki-counter').animate({
    'opacity': '1',
  }, 0)

  loveButtonParent.find('.suteki-counter').fadeIn('100')
  loveButtonParent.find('.suteki-counter').fadeOut('5000')
  loveButtonParent.find('.mask-color').css('height', STEKI_METER_HEIGHTS[numSteki])

  if (numSteki >= 10) {
    loveButtonParent.find('.flush-heart').addClass('target')
    loveButtonParent.addClass('liked', 400, 'swing', () => {
      loveButtonParent.find('.flush-heart').removeClass('target')
    })
    loveButtonParent.find('.mask-color').css('background', 'white')
    loveButtonParent.find('.love-button').addClass('suteki-scale')
    loveButtonParent.find('.suteki-counter-number').css('font-size', '30px')
  }

  loveButtonParent.parent().find('.love-button-count-span').html(function (i, val) {
    return numTotalSteki
  })
  loveButtonParent.parent().find('.suteki-counter-number').html(function (i, val) {
    return numSteki
  })
}

export function isHiragana(str){
  str = (str==null)?"":str;
  if(str.match(/^[ぁ-んー　]*$/)){    //"ー"の後ろの文字は全角スペースです。
    return true;
  }else{
    return false;
  }
}

/**
 * URLから指定パラメータの値を取得
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
export function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}