import * as Ajax from './ajax'

$(document).ready(function(){
  /**
   * matching controller
   */
  if(document.body.dataset.controller == 'matching') {
    if(document.body.dataset.action == 'edit_answers') {
      // ----------------------------------------
      // 初期処理
      // ----------------------------------------

      $('.matching-questions-footer-text-two').text("質問ページあと２つです！もう少し！");
      $('.matching-questions-footer-text-three').text("次で最後の質問です！");

      // ----------------------------------------
      // 流用したパーツの動作上書き
      // ----------------------------------------
      $('.override-with-close').click(() => {
        event.preventDefault();
        $("#retry-dialog").hide()
      })
      $('.override-with-redirect').click(() => {
        location.href = '/stylists/matching'
      })

      // ----------------------------------------
      // チェックボックス共通
      // ----------------------------------------

      // チェックボックスにグループ指定がある場合、同一グループのボタン同士では
      // radioボタンのような排他選択を行う
      $('.checkbox-answer').on('change', (e) => {
        // checked:onにされたかつ排他グループに属する場合は他を解除
        const groupName = e.target.dataset.group;
        if(e.target.checked && groupName != undefined && groupName.length > 0) {
          $('.checkbox-answer').each((i, tag) => {
            // チェックしたタグと違うDOM要素でかつ同じdata-group
            if(e.target != tag && groupName === tag.dataset.group) {
              tag.checked = false
            }
          })
        }
      });

      // inputのradioをcheckboxみたいにcheckedを外す
      var _radio = [];
      $('input.checkbox-answer').on('click', function() {
        var _this = $(this);
        var _name = _this.attr('name');
        var _val  = _this.val();
        if (_radio[_name] === '' || _radio[_name] === null || _radio[_name] === undefined) {
          _radio[_name] = _val;
        } else {
          if (_radio[_name] == _val) {
            _this.prop('checked', false);
            _radio[_name] = '';
          } else {
            _radio[_name] = _val;
          }
        }
      });

      // ----------------------------------------
      // 位置情報関連
      // ----------------------------------------


      // 郵便番号入力ボタン
      $('.button-input-zip').click(function() {
        $('.wrap-input-zip').show();
        $('.wrap-messsage-get-location').hide();
        $('.button-input-zip').addClass('common-btn-toggle-disable');
        $('.button-get-location').removeClass('common-btn-toggle-disable');
      });
      // 位置情報取得ボタン
      $('.button-get-location').click(function() {
        $('.wrap-input-zip').hide();
        $('.wrap-messsage-get-location').show();
        $('.button-get-location').addClass('common-btn-toggle-disable');
        $('.button-input-zip').removeClass('common-btn-toggle-disable');
      });
      // 位置情報の取得ボタン
      $('.button-get-location').click(function() {
        $('.wrap-messsage-get-location').show()
        getPosition((lat, lng) => {
          // 取得した座標をフォームに含める
          $('form').append($('<input/>', { type:'hidden',name: 'matching_answers[lat]', class: 'input-lat', value: lat}))
                    .append($('<input/>', { type:'hidden',name: 'matching_answers[lng]', class: 'input-lng', value: lng}))
          $('.messsage-get-location').text('位置情報を取得しました')
        }, (errorMessage) => {
          $('.messsage-get-location').text(errorMessage)
        })
      })

      // ----------------------------------------
      // 「次へ」ボタンを押したときのブロックごとのバリデーション
      // ----------------------------------------

      $('.change-view-button').click(function() {
        // 各チェックボックスをバリデーション

        // $blockElement＝ボタンを押したブロック(matching-questions-content)
        const $blockElement = $(this).parent().parent().parent()
        $blockElement.find('.matching-questions-footer-error').hide()

        // ---------- 選択肢チェック ----------
        selectCheckAndAlertInBlockElement($blockElement)

        // ---------- 住所チェック ----------
        if($blockElement.find('.question-location').length > 0) {
          $('.validation-alert-zip').hide()
          $blockElement.find('.question-location').find('.validation-alert').hide()
          if ($('.input-zip').val().length == 0 &&
              ($('.input-lat').length == 0 || $('.input-lng').length == 0)) {
            // どちらもセットなし
            // 郵便番号欄を表示中の場合
            if($('.input-zip').is(':visible')) {
              $('.validation-alert-zip').show()
            }
            else {
              $blockElement.find('.question-location').find('.validation-alert').show()
            }
            $blockElement.find('.matching-questions-footer-error').show()
            return true
          }
        }

        // ---------- 生年月日チェック ----------
        if($blockElement.find('.question-birthday').length > 0) {
          $('.question-birthday').find('.validation-alert').hide()

          $('.select-year').val() == '年' && $('.validation-alert.year').show()
          $('.select-month').val() == '月' && $('.validation-alert.month').show()
          $('.select-day').val() == '日' && $('.validation-alert.day').show()

          if($('.question-birthday').find('.validation-alert').is(':visible')){
            // いずれかが抜けている
            $blockElement.find('.matching-questions-footer-error').show()
            return true
          }
        }

        // エラーあったら中断
        if(hasError()) {
          return
        }

        // 次のブロック（あれば）可視化してスクロール
        if($(this).hasClass('changeview-one')) {
          $('.wrapper-second').show();
          $('.matching-questions-head-speech').text("質問ページあと３つです！");
          scrollToNextBlock()
        }
        else if($(this).hasClass('changeview-two')) {
          $('.wrapper-third').show();
          $('.matching-questions-head-speech').text("質問ページあと２つです！もう少し！");
          scrollToNextBlock()
        }
        else if($(this).hasClass('changeview-three')) {
          $('.wrapper-fourth').show();
          $('.matching-questions-head-speech').text("次で最後の質問です！");
          $('.fixed-bg-bottom').hide();
          $('.fixed-bg-wrap-last').hide();
          scrollToNextBlock()
        };

        // ----------------------------------------
        // 生年月日下の「次へ」でajaxにてプレPOST(データ量的にはこっちが本番)
        // ----------------------------------------
        if($(this).hasClass('changeview-three')) {
          let formData = $('.edit-matching-answers-form').serializeArray()
          console.log(JSON.stringify(formData))
          Ajax.postMatchingAnswers(formData,
            (rUid) => {
              $('.user-info').data('rUid', rUid)
            }, () => {})
        }
      })

      // ----------------------------------------
      // POST時のページ全体のバリデーション
      // ----------------------------------------
      $('form').submit(function (e) {

        // ---------- 最終ブロック内の選択肢チェック ----------
        const $blockElement = $('.wrapper-fourth')
        // バリデーションを行いエラー表示
        selectCheckAndAlertInBlockElement($blockElement)

        // エラーあり。中断
        if (hasError()) {
          e.preventDefault()
        }
        // 全項目にエラーがなければ、POST
        else {
          e.preventDefault()
          watchProgressAndAnimate()
        }
      })

      // ----------------------------------------
      // mathing/edit_answers functions
      // ----------------------------------------
      function selectCheckAndAlertInBlockElement($blockElement) {
        $blockElement.find('.matching-questions-footer-error').hide()
        $blockElement.find('.question-select').each(function(questionIndex, questionElement) {
          $(questionElement).find('.validation-alert').hide()
          let hasSelected = false
          // チェックボックスのチェック
          // 一つ以上選択されているか
          $(questionElement).find('.checkbox-answer').each(function(checkIndex, checkElemnt) {
            if(checkElemnt.checked) {
              hasSelected = true
              return true
            }
          })

          // 未選択のquestionがある
          if(!hasSelected) {
            $(questionElement).find('.validation-alert').show()
            $blockElement.find('.matching-questions-footer-error').show()
            return true
          }
        })
      }
      /**
       * どこかに項目エラーがあるかチェック
       */
      function hasError(){
        let result = false
        $('.matching-questions-footer-error').each(function(e) {
          if ($(this).is(':visible')) { // 非表示中のフォーム項目は必ずinvisibleな点もミソ
            result = true
          }
        })
        return result
      }
      /**
       * 次のブロック位置へスクロールする
       */
      function scrollToNextBlock() {
              $("html,body").animate({
                scrollTop: 535 + ($(window).scrollTop())
              }, 500);
      }
      /**
       * 現在地を処理する
       * @param {fnction} onSuccess 成功時コールバック
       * @param {fnction} onFailure 失敗時コールバック
       */
      function getPosition(onSuccess, onFailure) {
        // [不明, PERMISSION_DENIED, POSITION_UNAVAILABLE, TIMEOUT]
        const GEOLOCATION_API_ERRORS = ['現在位置が取得できませんでした', '位置情報の利用が許可されていません', '現在位置が取得できませんでした', 'タイムアウトになりました']

        // 現在地を取得
        navigator.geolocation.getCurrentPosition(
          // 取得成功した場合
          function(position) {
            onSuccess(position.coords.latitude, position.coords.longitude)
          },
          // 取得失敗した場合
          function(error) {
            onFailure(GEOLOCATION_API_ERRORS[error.code])
          }
        )
      }

      /**
       * プログレスの80%までのダミーアニメーションを実行
       * 80%に到達したらAPIで実際の進捗を取得、100%でリダイレクト
       */
      function watchProgressAndAnimate() {
        // かかる時間
        const TOTAL_TIME = 2000
        // ダミープログレスMAX値
        const MAX_COUNT = 80;
        $(".prosessiong").show();
        // ステップのパターンをランダムに選ぶ
        const step_infos = STEP_INFOS_PATTERNS[Math.floor(Math.random() * 7)]

        // 1個めのアニメーション
        const first_count = MAX_COUNT * step_infos[0].count_rate
        const first_time = TOTAL_TIME * step_infos[0].time_rate
        // 進捗率値
        animateProgressBarAndNumber(first_count, first_time)

        // N個めのアニメーション
        step_infos.slice(1).forEach((step_info, stepIndex) => {
          const count = MAX_COUNT * step_info.count_rate
          const previousCount = MAX_COUNT * step_infos[stepIndex].count_rate
          const time = TOTAL_TIME * step_info.time_rate

          // アニメーション開始時間を計算する
          let animationStartTime = 0
          if(stepIndex > 0) {
            animationStartTime = step_infos.slice(0, stepIndex + 1).reduce(function(accumulator, currentValue, reduceIndex) {
              const sum = reduceIndex == 1 ? TOTAL_TIME * accumulator.time_rate : accumulator
              return sum + TOTAL_TIME * currentValue.time_rate
            })
          }

          // アニメーションを予約
          setTimeout(function() {
            $('.matching-dialog-progress-percent-number').prop('number', previousCount)
            // アニメーション実施
            animateProgressBarAndNumber(count, time)
            // 最終ステップの場合、完了したらAPIによるリアル進捗の確認を開始
            if(step_infos.slice(1).length == stepIndex + 1) {
              let previeusProgress = previousCount
              const realProgressTimer = setInterval(() => {
                // rUidを指定してAPIにて進捗を確認
                Ajax.getMatchingProgress($('.user-info').data('rUid'), (progress) => {
                  $('.matching-dialog-progress-percent-number').prop('number', previeusProgress)
                  previeusProgress = progress
                  animateProgressBarAndNumber(progress, 500)
                  // サーバーで計算が完了
                  if(progress == 100) {
                    clearInterval(realProgressTimer)
                    document.location.href = $('.index-path').data('url')
                  }
                })
              }, 1000);
            }
          }, animationStartTime) // 前ステップまでにかかるはずの時間を待ってから開始
        })
      }

      /**
       * プログレスバーと進捗値をアニメーションさせる
       */
      function animateProgressBarAndNumber(count, time) {
        $('.matching-dialog-progress-percent-number').animateNumber({
          number: count
        }, {
          easing: 'swing',
          duration: time
        });
        // ゲージ値
        $('.matching-dialog-text-progress-gauge-bar').animate({
          width: count + '%'
        }, time, 'swing');
      }

      /**
       * プログレスのダミーアニメーションパターン定義
       */
      const STEP_INFOS_PATTERNS = [
        // B
        [
          { count_rate: 0.3, time_rate: 0.35},
          { count_rate: 1,  time_rate: 0.65},
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 }
        ],
        // C
        [
          { count_rate: 0.2, time_rate: 0.3},
          { count_rate: 0.3,  time_rate: 0.55},
          { count_rate: 1,  time_rate: 0.15 },
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 }
        ],
        // D
        [
          { count_rate: 0.8, time_rate: 0.15},
          { count_rate: 0.9,  time_rate: 0.25},
          { count_rate: 0.95,  time_rate: 0.4 },
          { count_rate: 1,  time_rate: 0.2 },
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 }
        ],
        // E
        [
          { count_rate: 0.3, time_rate: 0.2},
          { count_rate: 0.35,  time_rate: 0.2},
          { count_rate: 0.6,  time_rate: 0.2 },
          { count_rate: 0.65,  time_rate: 0.2 },
          { count_rate: 1,  time_rate: 0.2 },
          { count_rate: 1,  time_rate: 1 }
        ],
        // F
        [
          { count_rate: 0.03, time_rate: 0.125 },
          { count_rate: 0.23,  time_rate: 0.125 },
          { count_rate: 0.27,  time_rate: 0.125 },
          { count_rate: 0.48,  time_rate: 0.125 },
          { count_rate: 0.55,  time_rate: 0.125 },
          { count_rate: 0.69,  time_rate: 0.125 },
          { count_rate: 0.75,  time_rate: 0.125 },
          { count_rate: 1,  time_rate: 0.125 }
        ],
        // G
        [
          { count_rate: 0.03, time_rate: 0.125 },
          { count_rate: 0.18,  time_rate: 0.125 },
          { count_rate: 0.21,  time_rate: 0.125 },
          { count_rate: 0.39,  time_rate: 0.125 },
          { count_rate: 0.95,  time_rate: 0.2 },
          { count_rate: 1,  time_rate: 0.125 },
          { count_rate: 1,  time_rate: 0.5 },
          { count_rate: 1,  time_rate: 0.125 }
        ],
        // H
        [
          { count_rate: 0.6, time_rate: 0.16 },
          { count_rate: 0.65,  time_rate: 0.16 },
          { count_rate: 0.68,  time_rate: 0.16 },
          { count_rate: 0.77,  time_rate: 0.16 },
          { count_rate: 0.82,  time_rate: 0.16 },
          { count_rate: 1,  time_rate: 0.17 },
          { count_rate: 1,  time_rate: 0 },
          { count_rate: 1,  time_rate: 0 }
        ]
      ]
    }
  }
})
