$(document).ready(function(){
  /**
   * profile controller
   */
  if (document.body.dataset.controller == 'profile') {
    // 戻る禁止
    // history.pushState(null, null, null);
    // $(window).on("popstate", function (event) {
    //   if (!event.originalEvent.state) {
    //     history.pushState(null, null, null);
    //     return;
    //   }
    // });
    /**
     * edit_birthday action
     */
    if(document.body.dataset.action == 'edit_birthday') {
      // バリデーション
      $('.put-birthday-form').submit(function (e) {
        $('.validation-alert-birthday').hide()
        if($('.birthday-year').val() == '年' || $('.birthday-month').val() == '月' || $('.birthday-day').val() == '日') {
          $('.validation-alert-birthday').show()
          return false
        }
      })
    }
    /**
     * edit_address action
     */
    if(document.body.dataset.action == 'edit_address') {
      // バリデーション
      $('.put-address-form').submit(function (e) {
        $('.validation-alert-zip').hide()
        if($('.zip').val().length == 0) {
          $('.validation-alert-zip').show()
          return false
        }
        if($('.prefecture').val() == 0) {
          $('.validation-alert-prefecture').show()
          return false
        }
      })
    }
    if(document.body.dataset.action == 'edit_job') {

    }
    if(document.body.dataset.action == 'edit_contact') {
      $('form').on("ajax:success", (event) => {
        $('#dialog-finish-counseling').show()
        $('.profile-dialog-bg').show()
      }).on("ajax:error", (event) => {
        console.log("エラー")
      })
    }
  }
})

