$(document).ready(function(){
    if (document.body.dataset.controller == 'points') {
      

  let user_names = $('.point-card-header .salon-name');
  if (user_names) {
    $.each(user_names, function (index, el) {
      //半角全角の文字数に変換
      function charcount(str) {
        var len = 0;
        var str = escape(str);
        for (var i = 0; i < str.length; i++, len++) {
          if (str.charAt(i) == "%") {
            if (str.charAt(++i) == "u") {
              i += 3;
              len++;
            }
            i++;
          }
        }
        return len;
      }
      let text = $(el).text().trim();
      let text_charcount = charcount(text);
      if (text_charcount > 12) {
        $(el).css('letter-spacing', '-0.35px');
        $(el).css('font-size', '-14.5px');
        $(el).css('line-height', '1.4');
      }
      if (text_charcount > 24) {
        $(el).css('font-size', (16 - ((text_charcount - 24) * 0.32)) + 'px');
      }
    });
  }
    
      
      $(".about-points").click(function () {
          let sa_id = $(this).data("sa-id")
          let group_id = $(this).data("group-id")
          console.log(sa_id)
          $.ajax({
            url: "/user/points/salon_terms",
            data: {
                group_id: group_id,
                sa_id: sa_id
            }, 
            type: 'POST',
          }).done(function (data) {
              let point_expire_date = data.point_expire_date;
              let salon_point_settings = data.salon_point_settings;
              if (point_expire_date) {
                $(".point-expire-date").text("ポイントが付与された時点から" + point_expire_date + "日間となります。");
              } else {
                $(".point-expire-date").text("ポイントの有効期限はありません")
              }
              

              let li = "";
              let len = salon_point_settings.length
              for (var i = 0; i < len; i++) {
                  let target_menu_type = salon_point_settings[i].target_menu_type
                  li += " <li>"+ target_name(target_menu_type) + "：" + salon_point_settings[i].return_rate + "％でポイントが還元されます。</li>"
              }

              $(".point-return-rate").html(li);
              $(".points-terms").show();
              $(".point-card-shadow-view").show()
              

          }).fail((jqXHR, textStatus, errorThrown) => {
          })
          
      });

      $(".back-terms-point a").click(function () {
          $(".points-terms").hide();
          $(".point-card-shadow-view").hide();
          $(".points-usable-salon").hide();
      });

      $(".point-card-shadow-view").click(function () {
          $(".points-terms").hide();
          $(".point-card-shadow-view").hide()
          $(".points-usable-salon").hide();
      });

      
      $(".userable-salon a").click(function () {
          let sa_id = $(this).data("sa-id")
          let group_id = $(this).data("group-id")
          console.log(sa_id)
          console.log(group_id)
          $.ajax({
              url: "/user/points/usable_salon",
              data: {
                  group_id: group_id,
                  sa_id: sa_id
              },
              type: 'POST',
          }).done(function (data) {
              console.log(data)

              let li = "";
              if (data.salon_name) {
                  li += "<li>" + data.salon_name + "</li>";
                 
              } else {
                  
                let len = data.group_salon_names.length
                for (var i = 0; i < len; i++) {
                    li += "<li>" + data.group_salon_names[i] +"</li>"
                }
             
              }
              
              $(".usable-salon-list").html(li)
              
              $(".points-usable-salon").show();
              $(".point-card-shadow-view").show();
              

          }).fail((jqXHR, textStatus, errorThrown) => {
          })
      })

      function target_name(target_menu_type) {
          if (target_menu_type == 0) {
              return "全メニュー";
          } else if (target_menu_type == 1) {
              return "全技術メニュー"
          } else if (target_menu_type == 2) {
              return "全店販メニュー"
          }
      }
  }
})

