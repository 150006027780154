import * as Ajax from './ajax'

$(document).ready(function () {
  /**
   * SalonController
   */
  if (document.body.dataset.controller == 'sns_profile_url') {


    $(".dialog-open-twitter").click(function () {
      $('#dialog-twitter,#henkin-detail-dialog-bg').show();
    })

    $(".dialog-open-instagram").click(function () {
      $('#dialog-instagram,#henkin-detail-dialog-bg').show();
    })

    $(".sns_profile_url-dialog-close-button,.sns-share-dialog-02-close-text").click(function () {
        $('.dialog-sns,#henkin-detail-dialog-bg').hide();
    })


    $(".sns-share-dialog-02").scroll(function() {
      if ($(this).scrollTop() > 10) {
        $(".scroll-readmore").fadeOut();
      } else {
        $(".scroll-readmore").fadeIn();
      }
    });

    /**
     * create
     */
    $('form').submit(function (e) {
      // ---------- 最終ブロック内の選択肢チェック ----------
      var sns_acount_info = $('#owner_linked_profile_url_sns_account_info').val()
      var sns_type_radio = $('input[name="owner_linked_profile_url[sns_type]"]')

      var checked = false
      sns_type_radio.each(function (index, el) {
        if (el.checked) {
          checked = true
        }
      })

      var valid = true
      // エラーあり。中断
      if (sns_acount_info.length == 0) {
        valid = false
        $('.sns_profile_url_block-validation').show()
      } else {
        $('.sns_profile_url_block-validation').hide()
      }

      if (!checked) {
        valid = false
        $('.sns_select_block-validation').show()
      } else {
        $('.sns_select_block-validation').hide()
      }
      // 全項目にエラーがなければ、POST
      if (valid) {
        $('#dialog-sns-submit, #henkin-detail-dialog-bg').show();
        $('.sns_profile_url_block-validation').hide()
        return true
      } else {
        e.preventDefault()
        return false
      }
    })
  }
})
