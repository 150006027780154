$(document).ready(function(){
  /**
   * counselings_controller
   */
  if(document.body.dataset.controller == 'counseling') {
    // 戻る禁止
    // history.pushState(null, null, null)
    // $(window).on("popstate", function (event) {
    //   history.pushState(null, null, null);
    // })


    if (document.body.dataset.action == 'new_answers') {
    }
    /**
     * new_answers & edit_answer actions
     */
    if(document.body.dataset.action == 'new_answers' ||
      document.body.dataset.action == 'edit_answer') {
      
        //カウンセリングが完了時
      $('form').on("ajax:success", (event) => {
        $('#dialog-finish-counseling').show()
        $('.profile-dialog-bg').show()
      }).on("ajax:error", (event) => {
        console.log("エラー")
      })

      // radiobuttonの選択状態
      let radioStates = {}
      $('.checkbox-answer').each((i, tag) => {
        radioStates[tag.id] = tag.checked
      })
      // 回答チェックボックスのチェック時処理
      // radioの選択を属性でなくコードで行う
      $('.checkbox-answer').on('click', (e) => {
        if(radioStates[e.target.id]) {
          e.target.checked = false
        }
        else {
          // checked:onにされたかつ排他グループに属する場合は他を解除
          const groupName = e.target.dataset.group;
          if(e.target.checked && groupName.length > 0) {
            $('.checkbox-answer').each((i, tag) => {
              // チェックしたタグと違うDOM要素でかつ同じdata-group
              if(e.target != tag && groupName === tag.dataset.group) {
                tag.checked = false
              }
            })
          }
          // Level2の場合、上位要素にチェックなければチェック
          if(e.target.dataset.level == 2) {
            if($('.answer-' + e.target.dataset.parentId)[0].checked == false) {
              $('.answer-' + e.target.dataset.parentId).click()
            }
          }
          // Level1の場合、子を全解除する
          if(e.target.dataset.level == 1) {
            $(e.target).parent().parent().find('.checkbox-answer').each(function() {
              const element = $(this)[0]
              if(element.dataset.level == 2 && element.dataset.parentId != e.target.dataset.answerId) {
                element.checked = false
              }
            })
          }
        }
        // 最終的な選択状態を保存
        $('.checkbox-answer').each((i, tag) => {
          radioStates[tag.id] = tag.checked
        })
      });

        // 誕生日バリデーション
        $('form').on('submit', () => {
            // 未入力であればキャンセル
            const birthday_month = $('#birthday_month').val();
            const birthday_day = $('#birthday_day').val();
            const required_birthday = $('#required_birthday').val();
            // console.log('birthday_month: ', birthday_month);
            // console.log('birthday_day: ', birthday_day);
            // console.log('required_birthday: ', required_birthday);

            if (required_birthday == "true" && (birthday_month == '月' || birthday_day == '日')) {

                if ($('#birthday-form').find('.mykarte-tutorial-head-note').length == 0) {
                    // エラー表示
                    const message = $('<div class="mykarte-tutorial-head-note">誕生日を入力してください</div>').css('margin-top', '15px');
                    $('#birthday-form').append(message);
                }
            
                // alert('誕生日を入力してください。');
                return false;
            }
        })
    }
  }
});
