//
// カルテ写真に対してステキ
//
export function setKartePhotoLike (kSuid, kSuidbase, suidbase, photoid, uppedLikeCount, callback) {
  $.ajax({
    url: '/user/kartes/like_karte_photo',
    type: 'post',
    data: {
      k_suid: kSuid,
      k_suidbase: kSuidbase,
      suidbase: suidbase,
      photoid: photoid,
      like_count: uppedLikeCount
    }
  }).done(callback)
}

//
// カルテ共有コメントに対してステキ
//
export function setKarteCommentLike (kSuid, kSuidbase, suidbase, uppedLikeCount, callback) {
  $.ajax({
    url: '/user/kartes/like_karte_comment',
    type: 'post',
    data: {
      k_suid: kSuid,
      k_suidbase: kSuidbase,
      suidbase: suidbase,
      like_count: uppedLikeCount
    }
  }).done(callback)
}


/**
 * オススメコメントに自分のステキ数を設定する
 * @param {int} recommendId int
 * @param {int} count いいね数
 * @param {fnc} callback コールバック関数
 */
export function setRecommendLike(rRecommendId, count, callback) {
  $.ajax({
    url: '/r_recommend_likes',
    type: 'post',
    data: {
      r_recommend_id: rRecommendId,
      count: count
    }
  })
  .done(callback);
}


/**
 * 素敵なクチコミに自分のステキ数を設定する
 * @param {int} rReviewId レビューID
 * @param {int} count ステキ数
 * @param {fnc} callback コールバック関数
 */
export function setRReviewLike(rReviewId, count, callback) {
  $.ajax({
    url: '/r_review_likes',
    type: 'post',
    data: {
      r_review_id: rReviewId,
      count: count
    }
  })
  .done(callback);
}

//
// プライベートカルテ写真保存
//
export function setPrivateKartePhoto (kSuid, kSuidbase, photoid, image, callback) {
  $.ajax({
    url: '/user/kartes/upload_private_karte_photo',
    type: 'post',
    data: {
      k_suid: kSuid,
      k_suidbase: kSuidbase,
      photoid: photoid,
      image: image
    }
  }).done(callback)
}


//
// 共有されたカルテ写真一覧を取得する
//
export function getSharedKartePhotoList (ruid, callback) {
  $.ajax({
    url: '/user/kartes/get_shared_karte_photo_list',
    type: 'post',
    data: {
      ruid: ruid
    }
  }).done(callback)
}

//
// 共有されたカルテ写真一覧を取得する
//
export function getPurchaseHistory (ruid, callback) {
  $.ajax({
    url: '/user/kartes/purchase_history',
    type: 'post',
    data: {
      ruid: ruid
    }
  }).done(callback)
}

//
// プライベートカルテ写真削除
//
export function deletePrivateKartePhoto (kSuid, kSuidbase, photoid, callback) {
  $.ajax({
    url: '/user/kartes/delete_private_karte_photo',
    type: 'post',
    data: {
      k_suid: kSuid,
      k_suidbase: kSuidbase,
      photoid: photoid
    }
  }).done(callback)
}

//
// フォローする
//
export function followStylist (stekinaId, userId, resp, onSuccess, onFailure) {
  const mintServerPath = MINT_SERVER_PATH
  //alert("フォローする: " + location.search);
  $.ajax({
    url: mintServerPath + '/follow/' + stekinaId,
    type: 'post',
    data: {
      uid: userId,
      resp: resp,
      "fletcher-code": stekinaId
    }
  }).done(function (data) {
    // data.result['follow-count']
    console.log(data)
    console.log('success')
    onSuccess(0)
  }).fail(function (data) {
    console.log('failed')
    onFailure()
  })
}

//
// プロフィール付きでフォローする
//
export function followStylistWithProfile (stekinaId, userId, resp, name, pronounce, sex, is_counseling, onSuccess, onFailure) {
  const mintServerPath = MINT_SERVER_PATH
  //alert("フォローする: " + location.search);
  var params = {
    uid: userId,
    resp: resp,
    name: name,
    pronounce: pronounce,
    sex: sex,
    "fletcher-code": stekinaId
  }

 // is_counselingがtrueの場合は、'follow-request-for-counseling'、そうでなければ'follow'をURLに追加
 var endpoint = is_counseling ? '/follow-for-counseling/' : '/follow/';

  $.ajax({
    url: mintServerPath + endpoint + stekinaId,
    type: 'post',
    data: params,
    timeout: 10000
  }).done(function (data) {
    // data.result['follow-count']
    console.log(data)
    console.log('success')
    onSuccess(0)
  }).fail(function (data) {
    console.log('failed')
    onFailure()
  })
}

//
// アンフォローする
//
export function disfollowStylist (stekinaId, userId, onSuccess, onFailure) {
  const mintServerPath = MINT_SERVER_PATH
  //alert("フォロー解除する: " + location.search);
  $.ajax({
    url: mintServerPath + '/disfollow/' + stekinaId,
    type: 'post',
    data: {
      uid: userId,
      "fletcher-code": stekinaId
    }
  }).done(function (data) {
    console.log('成功')
    console.log(data)
    onSuccess()
  }).fail(function (data) {
    console.log('失敗')
    onFailure()
  })
}

//
// サロン全員をフォローする
//
export function followSalonAllStylists(saId, onSuccess, onFailure) {
  $.ajax({
    url: '/salon/' + saId + '/follow_salon_all_stylists',
    type: 'post',
  }).done(function (data) {
    onSuccess(data.result)
  }).fail(function (data) {
    onFailure()
  })
}

//
// フォロー状態を取得する
//
export function followState(suidbase, ruid, onSuccess, onFailure) {
  $.ajax({
    url: '/profile/follow_state',
    type: 'post',
    data: {suidbase: suidbase, ruid: ruid}
  }).done(function (data) {
    onSuccess(data.result)
  }).fail(function (data) {
    onFailure()
  })
}

//
// 進捗確認
//
export function getMatchingProgress (rUid, onSuccess) {
  $.ajax({
    url: MINT_SERVER_PATH + '/v2/users/fetch-matching-progress',
    type: 'post',
    data: { uid: rUid }
  }).done(function (data) {
    onSuccess(data.result.progress)
  })
}

//
// 画像をアップロード
//
export function uploadUserPhoto (imageData, onSuccess) {
  $.ajax({
    url: '/user/upload_photo',
    type: 'post',
    data: {
      image_data: imageData
    }
  }).done(function (data) {
    onSuccess(data.s3_path)
  })
}

//
// レビュー返信送信
//
export function sendReviewReplies(review_id, comment, onSuccess) { 
  $.ajax({
    url: '/r_review_replies',
    type: 'post',
    data: {
      id: review_id,
      comment: comment
    }
  }).done(function (data) {
    onSuccess(data)
  }).fail(function (data) {
    onFailure()
  })
}


export function check_occupied_reservation (stekinaId, start_date, end_date, m_suidbase, m_suid, onSuccess, onFailure) {
  const mintServerPath = MINT_SERVER_PATH
  $.ajax({
    url: mintServerPath + '/check-occupied-with-menu/' + stekinaId,
    type: 'post',
    data: {
      'fletcher-code': stekinaId,
      start_date: start_date,
      end_date: end_date,
      m_suidbase: m_suidbase,
      m_suid: m_suid
    }
  }).done(function (data) {
    onSuccess(data)
  }).fail(function (data) {
    onFailure()
  })
}

export function checkReservationPassword(stekinaId, password, onSuccess, onFailure) {
  $.ajax({
    url: '/profile/check_reservation_password',
    type: 'post',
    data: {
      stekina_id: stekinaId,
      password: password
    }
  }).done(function (data) {
    onSuccess(data.result)
  }).fail(function (data) {
    onFailure()
  })
}

/**
 * マッチング回答をPOSTする
 * @param {hash}} matchingAnswers 選択した回答の一覧
 */
export function postMatchingAnswers(formData, onSuccess, onFailure) {
  $.ajax({
    url: '/user/matching/calculate',
    type: 'post',
    data: formData,
  }).done(function (data) {
    onSuccess(data.r_uid)
  }).fail(function (data) {
    onFailure()
  })
}

/**
 * 予約をキャンセルする
 */
export function cancelReservation(ruid, r_reserve_id, onSuccess, onFailure) {
  const mintServerPath = MINT_SERVER_PATH
  $.ajax({
    url: mintServerPath + '/v2/reserve/cancel-reservation',
    type: 'post',
    data: {
      uid: ruid,
      r_reserve_id: r_reserve_id,
      cancel_from: 3 //LINEからキャンセル
    }
  }).done(function (data) {
    onSuccess(0)
  }).fail(function (data) {
    onFailure()
  })
}

/**
 * 予約を変更するためのURLを取得する
 */
export function getUpdateReservationUrl(r_reserve_id, channel_id, onSuccess, onFailure) {
  $.ajax({
    url: '/user/kartes/update_reservation_url',
    type: 'get',
    data: {
      r_reserve_id: r_reserve_id,
      channel_id: channel_id
    },
  }).done(function (data) {
    onSuccess(data.update_reserve_url)
  }).fail(function (data) {
    onFailure()
  })
}

/**
 * 予約を変更するためのURLを取得する
 */
export function checkReserveCancelPermition(r_reserve_id, onSuccess, onFailure) {
  $.ajax({
    url: '/user/kartes/check_cancel_permition',
    type: 'get',
    data: { r_reserve_id: r_reserve_id },
  }).done(function (data) {
    onSuccess(data.permit_cancel)
  }).fail(function (data) {
    onFailure()
  })
}

export function searchSuggests(query, onSuccess, onFailure) {
  $.ajax({
    url: '/suggests/search',
    type: 'get',
    data: { query: query },
  }).done(function (data) {
    onSuccess(data)
  }).fail(function (data) {
    onFailure()
  })
}