import * as Ajax from './ajax'

$(document).ready(function () {
  /**
   * SalonController
   */
  if (document.body.dataset.controller == 'salon') {

    /**
     * show
     */
    if (document.body.dataset.action == 'show') {
      $('.follow-button-triger').click(function(e) {
        Ajax.followSalonAllStylists($('header').data('sa-id'), (result) => {
          if(result) {
            // 全フォローボタンにフォロー済みclassを適用
            $('.follow-button-triger').addClass('follow-button-active')
            $('.follow-button-text').text("フォロー中")
          }
        }, () => {})
      })
    }
  }
})
