import * as Ajax from './ajax'




$(document).ready(function () {

  //文字のバイト数を取得する関数
  function charcount(str) {
    var len = 0;
    var str = escape(str);
    for (var i = 0; i < str.length; i++, len++) {
      if (str.charAt(i) == "%") {
        if (str.charAt(++i) == "u") {
          i += 3;
          len++;
        }
        i++;
      }
    }
    return len;
  }


  // //取得したスタイリスト名をバイト数に変換
  let user_names = $('.mystylist-columns-01-data-username');
  if (user_names) {
    $.each(user_names, function (index, el) {
      //半角全角の文字数に変換
      function charcount(str) {
        var len = 0;
        var str = escape(str);
        for (var i = 0; i < str.length; i++, len++) {
          if (str.charAt(i) == "%") {
            if (str.charAt(++i) == "u") {
              i += 3;
              len++;
            }
            i++;
          }
        }
        return len;
      }
      let text = $(el).text().trim();
      let text_charcount = charcount(text);
      if (text_charcount > 12) {
        $(el).css('letter-spacing', '-0.35px');
        $(el).css('font-size', '-14.5px');
        $(el).css('line-height', '1.38');
      }
      if (text_charcount > 24) {
        $(el).css('font-size', (16 - ((text_charcount - 24) * 0.32)) + 'px');
      }
    });
  }

  let salon_names = $('.mystylist-columns-01-data-salonname');
  if (salon_names) {
    $.each(salon_names, function (index, el) {
      //半角全角の文字数に変換
      function charcount(str) {
        var len = 0;
        var str = escape(str);
        for (var i = 0; i < str.length; i++, len++) {
          if (str.charAt(i) == "%") {
            if (str.charAt(++i) == "u") {
              i += 3;
              len++;
            }
            i++;
          }
        }
        return len;
      }
      let salontext = $(el).text().trim();
      let salontext_charcount = charcount(salontext);
      if (salontext_charcount > 16) {
        $(el).css('font-size', (11 - ((salontext_charcount - 16) * 0.05)) + 'px');
        $(el).css('letter-spacing', '-0.35px');
        $(el).css('line-height', '1.25');
      }
    });
  }
  

  /**
  * users_controller
  */
  if (document.body.dataset.controller == 'users') {
    if (document.body.dataset.action == 'edit') {
      if (window.history && window.history.pushState) {
        window.history.pushState('forward', null, '#forward');
        $(window).on('popstate', function () {
          $("#user_profile_form").submit()
        });
      }

      //プロフィール画面の保存するボタン押下時
      $('form').submit(function (e) {

        var pronounce = $('#pronounce').val()
        var phone = $('#phone').val()
        var zipcode = $('#zip').val()
        var error = false
        if (pronounce.length > 0 && !isHiragana(pronounce)) {
          // $(".profile-pronounce-validation").show()
          alert('よみがなはひらがなで記入してください。')
          error = true
        } else {
          $(".profile-pronounce-validation").hide()
        }

        if (zipcode.length > 0 && !$.isNumeric(zipcode)) {
          // $(".profile-zip-validation").show()
          alert('郵便番号は数字で記入してください。')
          error = true
        } else {
          $(".profile-zip-validation").hide()
        }

        if (phone.length > 0 && !$.isNumeric(phone)) {
          // $(".profile-phone-validation").show()
          alert('電話番号は数字で記入してください。')
          error = true
        } else {
          $(".profile-phone-validation").hide()
        }

        if (error) {
          return false
        }

        $(".profile-validation").hide()
      })

    }

    function isHiragana(str) {
      str = (str == null) ? "" : str;
      if (str.match(/^[ぁ-んー　]*$/)) {    //"ー"の後ろの文字は全角スペースです。
        return true;
      } else {
        return false;
      }
    }


  }
})
