$(document).ready(function(){
  /**
   * special_request controller
   */
  if(document.body.dataset.controller == 'special_requests') {
    if($('#dialog-emergency-discount-finish').length > 0) {
      $('.survay-dialog-bg').fadeIn()
      $('#dialog-emergency-discount-finish').fadeIn()
    }

    /**
     * emergency_new
     */
    if(document.body.dataset.action == 'emergency_new') {

      // 前提条件（ステキナ登録を確認）
      if($('.need-registration-dialog').length > 0) {
        $('.survay-dialog-bg').fadeIn()
        $('.need-registration-dialog').fadeIn()
      }
      
      // バリデーション
      $('form').submit(function (e) {

        $('.discount-form-valication').hide()
        $('.has-any-errors-alert').hide()
        let hasError = false
        // 都道府県
        if($('.prefecture').val() == '未選択') {
          $('.prefecture').parent().find('.discount-form-valication').show()
          hasError = true
        }
        // 区市町村
        if($('.city').val().length == 0) {
          $('.city').parent().find('.discount-form-valication').show()
          hasError = true
        }

        if(hasError) {
          $('.has-any-errors-alert').show()
          e.preventDefault()
        }
      })
    }
  }
})
