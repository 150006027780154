$(document).ready(function(){
  /**
   * questionnaire controller
   */
  if(document.body.dataset.controller == 'questionnaire') {
    /**
     * new action
     */
    if(document.body.dataset.action == 'new') {
      $(".survay-dialog-load-page").hide()
      setTimeout(function() {
          $(".survay-dialog-bg").fadeIn()
          $(".survay-dialog-load-page").fadeIn()
      }, 500)

      $('.close-survay-dialog-load-page').on('click', function() {
        $('.survay-dialog-load-page').hide()
        $('.survay-dialog-bg').hide()
      });

      const $radios = $('input[type="radio"]')
      const questionCount = $('.question').length

      // 回答チェックボックスのチェック時処理
      // 指定がある場合、radioボタンのような排他選択
      $('.checkbox-answer').on('change', (e) => {
        // checked:onにされたかつ排他グループに属する場合は他を解除
        const groupName = e.target.dataset.group;
        if(e.target.checked && groupName != null && groupName.length > 0) {
          $('.checkbox-answer').each((i, tag) => {
            // チェックしたタグと違うDOM要素でかつ同じdata-group
            if(e.target != tag && groupName === tag.dataset.group) {
              tag.checked = false
              console.log(tag)
            }
          })
        }
        $('.nokori-number').html('' + (questionCount - $('input[type="radio"]').filter(':checked').length))
      });
      // バリデーション
      $('form').submit(function (e) {
        let hasError = false
        // チェックボックス
        $('.question').each(function(questionIndex, questionElement) {
          // 必須項目を確認
          if($(this).data('require')) {
            $(questionElement).find('.validation-alert').hide()
            let hasSelected = false
            // 一つ以上選択されているか
            $(questionElement).find('.checkbox-answer').each(function(checkIndex, checkElemnt) {
              if(checkElemnt.checked) {
                hasSelected = true
                return false
              }
            })
            // 選択がない場合該当項目のアラートエリア表示
            if(!hasSelected) {
              hasError = true
              $(questionElement).find('.validation-alert').show()
              $('.survay-submit-footer-error').show()
            }
          }
        })

        if(hasError) {
          e.preventDefault()
        }
        else {
          $('.survay-dialog-bg').fadeIn()
          $('.survay-dialog-finish-answer').fadeIn()
        }
      })
      // 終了後ダイアログからの遷移
      $('.go-to-page-of-lime-discount').click(function() {
        location.href = '/user/discount'
      })
    }
  }
})
