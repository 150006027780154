$(document).ready(function(){
  /**
   * counselings_controller
   */
  if(document.body.dataset.controller == 'tester') {
    // 回答チェックボックスのチェック時処理
    // 指定がある場合、radioボタンのような排他選択
    $('.checkbox-answer').on('change', (e) => {
      // checked:onにされたかつ排他グループに属する場合は他を解除
      const groupName = e.target.dataset.group;
      if(e.target.checked && groupName != null && groupName.length > 0) {
        $('.checkbox-answer').each((i, tag) => {
          // チェックしたタグと違うDOM要素でかつ同じdata-group
          if(e.target != tag && groupName === tag.dataset.group) {
            tag.checked = false
          }
        })
      }
    });
    // バリデーション
    $('form').submit(function (e) {
      let formHasError = false
      $('.matching-questions-footer-error').hide()

      // チェックボックス
      $('.question').each(function(questionIndex, questionElement) {
        if($(this).data('require')) {
          $(questionElement).find('.validation-alert').hide()
          let hasSelected = false
          // 一つ以上選択されているか
          $(questionElement).find('.checkbox-answer').each(function(checkIndex, checkElemnt) {
            if(checkElemnt.checked) {
              hasSelected = true
              return false
            }
          })
          if(!hasSelected) {
            formHasError = true
            e.preventDefault()
            $(questionElement).find('.validation-alert').show()
          }
        }
      })
      // 全体で一つ以上エラーがある
      if(formHasError) {
        $('.matching-questions-footer-error').show()
      }
      // バリデーションを通過
      else {
        $('.complete-dialog').show()
      }
    })
  }
})
