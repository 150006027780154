// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require('@rails/ujs').start()
require('@rails/activestorage').start()
require('jquery')
import Rails from '@rails/ujs';
Rails.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import './ajax';
import './common';
import './pages';
import './users';
import './counselings';
import './tester';
import './kartes';
import './matching';
import './inview';
import './stylists';
import './employee_salon';
import './questionnaires';
import './special_request';
import './profile';
import './salon';
import './discount';
import './points';