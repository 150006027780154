import * as Common from './common'
import * as Ajax from './ajax'

$(document).ready(function () {
  if (document.body.dataset.controller == 'pages') {
    /**
     * index_new action
     * メディアトップ
     *
     * stylist_rankings
     * ステキな美容師（LiME側の表示）
     */
    if (document.body.dataset.action == 'index' ||
      document.body.dataset.action == 'stylist_rankings' ||
      document.body.dataset.action == 'stylist_rankings_body') {

      if ($('.stekina-media-top-mv-search-parameters-top-two').length) {
        var d = new Date();
        var hours = d.getHours()
        var minutes = d.getMinutes()
        var time
        if (hours == 23 && minutes >= 30) {
          time = 1
        } else {
          time = (hours+1) * 2 + (minutes >= 32 ? 1 : 0)
        }

        $('#time').val(time)
      }
      //スクロールイベント処理
      mediaScrollAction();

      //サロン表示の文字サイズ調整(render_async後に)
      $('.salon-card-cover-name > p').each(function() {
        var card_salonname_text = $(this).text();
        var card_salonname_count = charcount(card_salonname_text);
        if (card_salonname_count > 26 && card_salonname_count < 34) {
          $(".salon-card-cover-name > p").css('font-size', '13px');
          $(".salon-card-cover-name > p").css('letter-spacing', '0.5px');
        } else if (card_salonname_count > 35) {
          $(".salon-card-cover-name > p").css('font-size', '11px');
          $(".salon-card-cover-name > p").css('letter-spacing', '0.5px');
        }
      });

      document.addEventListener("reviews-part", function (event) {
        setLoveButtonClick("reviews-part");
      })

      document.addEventListener("recommends-part", function (event) {
        setLoveButtonClick("recommends-part");
      })

      // サジェストの設定
      setSearchSuggest();
      setLoveButtonClick("reviews-part");
      setLoveButtonClick("recommends-part");

    }

    if (document.body.dataset.action == 'stylists_list' ||
      document.body.dataset.action == 'stylist_rankings' ||
      document.body.dataset.action == 'stylist_rankings_body') {
        $(window).scroll(function() {
          if ($(this).scrollTop() > 300) {
            $('body').addClass('bg-stekina-black')
            $('body').removeClass('bg-brown')
          } else {
            $('body').addClass('bg-brown')
            $('body').removeClass('bg-stekina-black')
          }
        });
    }

    // 自然検索かどうかチェック
    var is_organic = SEARCH_ENGINES.some(function (value) {
      return (document.referrer.indexOf(value)) > 0
    });

    //
    // すべてのアンカーリンクにクエリパラメータ調整
    // やってること:
    //  1. viaパラメータを適切な値に調整（変動）
    //  2. serviceパラメータにmediaをセット（固定）
    //  3. register_fromパラメータに3をセット（固定）
    $(document).find("a").each(function() {
      var str = this.href;
      var via = getParam('via')

      // クエリパラメータ書き換えていいのは /salon と /profileだけ
      if (~str.indexOf('//stekina.com/profile') ||
        ~str.indexOf('//test.stekina.com/profile') ||
        ~str.indexOf('//stekina.com/salon') ||
        ~str.indexOf('//test.stekina.com/salon')) {

        // 広告からきた場合はクエリパラメータにvia=adsが入っているはず
        if (via == 'ads') {
          this.search = "?via=ads&service=media&register_from=3";
        // 広告でなければ自然検索かどうかチェック
        } else {
          if (is_organic) {
            this.search = "?via=organic&service=media&register_from=3";
          // どれでもなければviaは記録しない
          } else {
            this.search = "?service=media&register_from=3";
          }
        }
      }
    });
  }
})

function setLoveButtonClick(elname) {
  $('.' + elname + ' .love-button-click').click(function () {
    click_love_button($(this))
  })
}


//
//メディア画面でのスクロールアクション
//
function mediaScrollAction() {
  var topmvheight = 292;

  $(".wrapper-media-wrap").on('scroll', function () {
    var scroll = $(this).scrollTop();
    if (scroll > 1000) {
      $('.fixed-button-bottom-ai-wrap').fadeIn();
      $('.stekina-media-top-mv-bg').hide();
    } else {
      $('.fixed-button-bottom-ai-wrap').fadeOut();
      $('.stekina-media-top-mv-bg').show();
    }

    /* Your app code here. */
    if (scroll < 1) {
      $('.stekina-media-top-mv-bg-img').velocity({scale: (1-(scroll/400))}, {duration: 0, easing: 'easeInOutQuad'});
      $('.stekina-media-top-mv-bg').velocity({height: (topmvheight-(scroll))}, {duration: 0, easing: 'easeInOutQuad'});
    } else {
      $('.stekina-media-top-mv-bg-img').velocity({scale: 1}, {duration: 0, easing: 'easeInOutQuad'});
      $('.stekina-media-top-mv-bg').velocity({height: topmvheight}, { duration: 0, easing: 'easeInOutQuad'});
    }
  });
}

//文字サイズゴニョゴニョ
function charcount(str) {
  var len = 0;
  var str = escape(str);
  for (var i = 0; i < str.length; i++, len++) {
    if (str.charAt(i) == "%") {
      if (str.charAt(++i) == "u") {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}


// ----------------------------------------
// ステキボタン
// stylists.jsのものとは別枠
// ----------------------------------------

// ステキボタン押下

function click_love_button(el) {
  let numSteki = $(el).data('num-suteki');
  let numTotalSteki = $(el).data('num-total-suteki');
  if (numSteki >= 10) {
    return
  }

  const rRecommendId = $(el).data('r-recommend-id');
  const rReviewId = $(el).data('r-review-id');
  const loveButton = $(el)

  Common.setStekiWithAnimate(loveButton, ++numSteki, ++numTotalSteki)

  if (rRecommendId) {
    Ajax.setRecommendLike(rRecommendId, numSteki, (result) => {})
  } else {
    Ajax.setRReviewLike(rReviewId, numSteki, (result) => {})
  }
}


// ---------------------------
// 検索のサジェスト
// ---------------------------
function setSearchSuggest() {
  const suggestInput = document.getElementById('query');
  $(suggestInput).autocomplete({
    source: function( request, response ) {
      let lastTerm = request.term.match(/[^\s　]*$/)[0];
      Ajax.searchSuggests(lastTerm, function(results){
        const list = $.map(results.suggests, function(suggest){
          return suggest.name;
        })
        response(list);
      }, (result) => {})
    },
    select: function( event, ui ) {
      if (ui.item) {
        let lastTerm = suggestInput.value.match(/[^\s　]*$/)[0];
        let nextValue = suggestInput.value.replace(/([^\s　]*)$/, ui.item.label)
        suggestInput.value = nextValue;
        return false;
      }
    }
  });
}

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


var SEARCH_ENGINES = [
  "360.cn",
  "www.alice.com",
  "aliceadsl.fr",
  "www.alltheweb.com",
  "www.altavista.com",
  "www.aol.com",
  "www.ask.com",
  "search.aol.fr",
  "alicesuche.aol.de",
  "search.auone.jp",
  "isearch.avg.com",
  "search.babylon.com",
  "www.baidu.com",
  "biglobe.ne.jp",
  "www.bing.com",
  "search.centrum.cz",
  "search.comcast.net",
  "search.conduit.com",
  "www.cnn.com",
  "www.daum.net",
  "duckduckgo.com",
  "www.ecosia.org",
  "www.ekolay.net",
  "www.eniro.se",
  "www.globo.combusca",
  "go.mail.ru",
  "goo.ne.jp",
  "www.google",
  "www.haosou.coms",
  "search.incredimail.com",
  "www.kvasir.no",
  "www.bing.com",
  "www.lycos.com",
  "search.lycos.de",
  "www.mamma.com",
  "www.msn.com",
  "money.msn.com",
  "local.msn.com",
  "www.mynet.com",
  "najdi.si",
  "www.naver.com",
  "search.netscape.com",
  "szukaj.onet.pl",
  "www.ozu.es",
  "www.qwant.com",
  "rakuten.co.jp",
  "rambler.ru",
  "search-results.com",
  "search.smt.docomo.ne.jp",
  "sesam.no",
  "www.seznam.cz",
  "www.so.coms",
  "www.sogou.com",
  "www.startsiden.nosok",
  "www.szukacz.pl",
  "buscador.terra.com.br",
  "search.tut.by",
  "search.ukr.net",
  "search.virgilio.it",
  "www.voila.fr",
  "www.wp.pl",
  "yahoo.",
  "www.yandex.com",
  "yandex.ru",
  "www.yam.com"
]
