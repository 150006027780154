import * as Ajax from './ajax'

$(document).ready(function () {
  /**
   * EmployeeSalonController
   */
  if (document.body.dataset.controller == 'employee_salon') {

    /**
     * index
     */
    if (document.body.dataset.action == 'index') {
      $('.check_followed').click(function() {
        $('.follow-dialog-mykarte').show();
      })

      $('.close-follow-dialog-mykarte').click(function() {
        $('.follow-dialog-mykarte').hide();
      })
    }
  }
})
